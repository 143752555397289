import { throttle } from "throttle-debounce"

const TABLET_MAX = 959

export function useIsMobile() {
  const isMobile = ref(windowIsSmall())

  function windowIsSmall() {
    if (typeof window === "undefined") {
      return false
    }
    return window.innerWidth < TABLET_MAX
  }

  function onResize() {
    isMobile.value = windowIsSmall()
  }

  const throttledResize = throttle(100, onResize)

  onMounted(() => {
    window.addEventListener("resize", throttledResize)
  })

  onUnmounted(() => {
    window.removeEventListener("resize", throttledResize)
  })

  return isMobile
}
