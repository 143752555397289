<template>
  <div ref="root" :class="['cs-toc', inSidebar && 'cs-toc-sidebar']">
    <component v-if="canShow" :is="htmlType">
      <nav class="cs-toc-section">
        <Headline :level="6" class="toc-headline"> Table of Contents </Headline>

        <ul>
          <li
            v-for="headline in pageHeadlines"
            :key="headline.id"
            :class="{
              'h3-headline': headline.tagName === 'H3',
            }"
          >
            <a
              :href="'#' + headline.id"
              :class="{
                current: currentScrollSection?.id === headline.id,
              }"
            >
              {{ headline.innerText }}
            </a>
          </li>
        </ul>
      </nav>
    </component>
  </div>
</template>

<script setup>
  import { throttle } from "throttle-debounce"
  import { useIsMobile } from "~/composables/useIsMobile"

  const singleColumnTemplates = ["content-page", "learning-guide"]

  const Container = defineAsyncComponent(
    () => import("~/components/Container/index.vue"),
  )

  const props = defineProps({
    element: {
      type: Object,
      default: null,
    },
    inSidebar: {
      type: Boolean,
      default: false,
    },
  })

  const root = ref(null)
  const pageHeadlines = ref([])
  const currentScrollSection = ref(null)
  const isSingleColumn = ref(false)

  const isMobile = useIsMobile()

  const htmlType = computed(() => {
    return props.inSidebar ? "div" : Container
  })

  let mutationObserver = null

  function getPageHeadlines() {
    const headlineNodeList = document.querySelectorAll(
      ".headline[id]:not(h1, h4, h5, h6)",
    )
    pageHeadlines.value = [...headlineNodeList]
  }

  function checkForSingleColumnTemplate() {
    const { template } = root.value.dataset
    isSingleColumn.value = template && singleColumnTemplates.includes(template)
  }

  function watchForContentUpdate() {
    mutationObserver = new MutationObserver(getPageHeadlines)

    const contentEl = document.querySelector("#content")

    const observerOptions = {
      childList: true,
      subtree: true,
    }

    if (contentEl) {
      mutationObserver.observe(contentEl, observerOptions)
    }
  }

  function removeContentWatcher() {
    mutationObserver.disconnect()
  }

  function onScroll() {
    if (pageHeadlines.value.length) {
      currentScrollSection.value = pageHeadlines.value.findLast(
        (el) => el.offsetTop - 100 < window.scrollY,
      )
    }
  }

  const throttledScroll = throttle(200, onScroll)

  function watchScrollPosition() {
    document.addEventListener("scroll", throttledScroll)
  }

  function removeScrollWatcher() {
    document.removeEventListener("scroll", throttledScroll)
  }

  const canShow = computed(() => {
    if (props.inSidebar) {
      return !isMobile.value && pageHeadlines.value.length
    } else if (!isSingleColumn.value) {
      return isMobile.value && pageHeadlines.value.length
    } else {
      return true
    }
  })

  watch(isMobile, (newIsMobile) => {
    if (!newIsMobile && props.inSidebar) {
      watchScrollPosition()
    } else {
      removeScrollWatcher()
    }
  })

  onMounted(() => {
    getPageHeadlines()
    checkForSingleColumnTemplate()
    watchForContentUpdate()

    if (!isMobile.value && props.inSidebar) {
      watchScrollPosition()
    }
  })

  onUnmounted(() => {
    removeScrollWatcher()
    removeContentWatcher()
  })
</script>

<script>
  export default {
    name: "TableOfContents",
  }
</script>

<style lang="scss">
  .cs-toc {
    @include viewport("md", "lg") {
      &-sidebar {
        padding-top: 16px;
      }
    }

    &-section {
      padding: $gutter;
      background: $blue-tint;
      border-radius: $border-radius-large;
    }

    .toc-headline {
      margin: 0 0 12px;
      padding: 0;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0;
    }
    li {
      margin: 0;
      padding: 0;

      &.h3-headline {
        padding-left: 16px;
      }
    }
    a {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;
      font-weight: 700;
      text-decoration: none;
      border-radius: 6px;
      transition: background-color 0.4s;

      svg {
        display: inline-block;
        flex: 0 0 auto;
        height: 1.2em;
        width: 1.2em;
      }
    }
  }
</style>
